.contact {
    height: 100vh;
    position: relative;
}

.contact-bg {
    width: 20px;
    height: 100%;
    background-color: #616161;
    position: absolute;
}

.contact-wrapper {
    padding: 50px;
    display: flex;
}

.contact-left {
    flex: 1;
}

.contact-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contact-title {
    font-size: 60px;
    width: 80%;
}

.contact-info-item {
    display: flex;
    align-items: center;
    margin: 30px 0px;
    font-weight: 300;
    width: 70%;
}

.contact-icon {
    width: 30px;
    height: 30px;
    margin-right: 20px;
}

.contact-desc {
    font-weight: 200;
}
a { 
  text-decoration: none;
  font-size: 15px;
  font-weight: bold;
}
form {
    margin-top: 20px;
}

input {
    width: 50%;
    height: 50px;
    border: none;
    border-bottom: 1px solid black;
    margin: 10px 0px;
    font-size: 14px;
    padding-left: 10px;
}

textarea {
    width: 100%;
    margin: 10px 0px;
    font-size: 14px;
    padding-left: 10px;
}

button {
    border: none;
    padding: 15px;
    /* background-color: white;
    color: black; */
    font-weight: 500;
    cursor: pointer;
}

input:focus {
  outline: none !important;
  border:1px solid #616161;
  box-shadow: 0 0 10px #719ECE;
}

textarea:focus {
  outline: none !important;
  border:1px solid #616161;
  box-shadow: 0 0 10px #719ECE;
}


@media screen and (max-width: 480px) {
    .contact-wrapper {
      flex-direction: column;
      padding: 0px 50px;
    }
  
    .contact-title {
      font-size: 30px;
    }
  
    .contact-info-item {
      margin: 20px 0px;
      width: 100%;
    }
  
    .contact-desc {
      display: none;
    }
  
    a { 
      text-decoration: none;
      font-size: 13px;
      font-weight: bold;
    }

    form {
      margin-top: 0px;
      display: flex;
      flex-wrap: wrap;
    }
  
    input {
      width: 35%;
      height: 40px;
      margin: 10px;
      margin-left: 0;
    }
  
    button {
      margin-top: 10px;
    }
  }

