.intro { 
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.intro-left { 
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.intro-right { 
    flex: 1;
    position: relative;
}

.intro-left-wrapper { 
    padding:50px;
    height: 50%;
    /* display: flex; */
    flex-direction: column;
    justify-content: space-between;
}

.intro-intro { 
    font-size: 30px;
    font-weight: 300;
}

.intro-name { 
    font-size: 60px;
}

.intro-title { 
    height: 50px;
    overflow: hidden;
}

.intro-title-wrapper { 
    height: 100%;
    animation: move 10s ease-in-out infinite alternate;
}

.intro-description{
    padding-top:3em;
}

.intro-button{
    border: none;
    padding: 15px;
    background-color: #616161;
    color: white;
    font-weight: 500;
    cursor: pointer;
}
@keyframes move { 
    25%{
        transform: translateY(-50px);
    }
    50%{
        transform: translateY(-100px);
    }
    75%{
        transform: translateY(-150px);
    }
    100%{
        transform: translateY(-200px);
    }
}

.intro-title-item { 
    height: 50px;
    font-size: 30px;
    font-weight: bold;
    color : red;
    display:flex;
    align-items: center;
    justify-content: center;

}

.intro-img { 
    width: 100%;
    height: 100%;
    object-fit: cover;
    position:absolute;
}

.intro-bg { 
    clip-path: polygon(100% 0%, 100% 51%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
  background-color: #59b256;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.intro-scroll
{ 
    bottom : 30px; 
    position : absolute; 
    transform : translate(-50);
    /* border : 2px solid rgb(0, 0, 0);  */
    padding : 10px 10px 20px;
    border-radius:  30px; 
}
.intro-scroll-btn span
{ 
    display: block;
	width: 20px;
	height: 20px;
	border-bottom: 2px solid rgb(0, 0, 0);
	border-right: 2px solid rgb(0, 0, 0);
	transform: rotate(45deg);
	margin: -10px;
    font-size : 8px; 
}
.intro-scroll-btn
{ 
    animation: mover 1s infinite alternate
}

@keyframes mover 
{ 
    0%{transform:  translateY(0);}
    100%{transform:  translateY(10px);}
}

@media screen and (max-width: 1000px) {
 
    .intro-title-item { 
        font-size: 23px;
    }
  }

@media screen and (max-width: 480px) {
    .intro {
      flex-direction: column;
    }

    .intro-intro { 
        font-size: 28px;
    }
  
    .intro-left-wrapper {
    
      /* padding: 10px; */
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 100%;
      display: flex;
    }
  
    .intro-desc , .intro-bg ,.intro-img{
      display: none;
    }
    
    .intro-title-item { 
        font-size: 20px;
        text-align: center;
        justify-content: center;
    }

  }