.project {
    width: 45%;
    height: 33vh;
    margin: 20px 10px;
    border: 2px solid rgb(243, 242, 242);
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
    position: relative;

}

.project:hover { 
    height: 40vh;
}

.project-browser {
    height: 20px;
    background-color: rgb(243, 242, 242);
    display: flex;
    align-items: center;
    position: sticky;
    z-index: 2;
}

.project-circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin: 3px;
    background-color: white;
}

.project-img {
    width: 100%;
    /* transition: all 10s ease; */
}
.project-image-overlay { 
    opacity: 0;
}

.project:hover .project-img {
    /* transform: scale(1.2); */
    opacity: 0.1;
    transition: opacity .2s, visibility .2s;

}


.project-image-overlay{
    position: absolute;
    top: 2em;
    bottom: 0;
    left: 0;
    right: 0;
    height: 80%;
    width: 100%;
    text-align: center;
    opacity: 0;
    transition: .5s ease;

}
.project:hover .project-image-overlay { 
    opacity: 1;
}

.project-image-overlay-title { 
    font-size: 20px;
    font-weight: bold;
    text-decoration: underline;
    
}

.project-image-overlay-desc { 
    font-size: 14px;
}

@media screen and (max-width:700px) {
    .project {
        width: 100%;
        height: auto;
        max-height: 30em;
    }

    .project:hover { 
        height: 50vh;
    }


    .project-image-overlay{
        top: 2em;
        width: 100%;
        text-align: center;
    }
    
}
