.centered{ 
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75);
}

.modal {
  position: fixed;
  top: 20vh;
  left: 5%;
  width: 90%;
  background-color: white;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 30;
  animation: slide-down 300ms ease-out forwards;
}
/* 
@media (min-width: 768px) {
  .modal {
    width: 40rem;
    left: calc(50% - 20rem);
  }
}
 */

.spinner {
    display: inline-block;
    width: 100%;
    height: 100vh;
  }
.spinner:after {
  position: fixed;
  left: 50%;
  top: 44%;
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  /* margin: 8px; */
  border-radius: 50%;
  border: 6px solid teal;
  border-color: teal transparent teal transparent;
  animation: spinner 1.2s linear infinite;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
  
@media screen and (max-width: 480px) {
  .spinner:after {
    left: 41%;
    top: 44%;
   
  }
}

