.projectList{
    padding: 50px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.projectList-texts {
    width: 65%;
}

.projectList-title {
    font-size: 50px;
    font-weight: 600;
}

.projectList-description {
    margin: 20px 0px;
}

.projectList-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

@media screen and (max-width: 700px) {
    .projectList-list {
      justify-content: center;
    }
}


@media screen and (max-width: 480px) {
    .projectList {
      padding: 10px;
    }
  
    .projectList-texts {
      width: 100%;
    }
  
    .projectList-desc {
      display: none;
    }
  }