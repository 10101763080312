.experience 
{ 
    height: 100vh;
    display: flex;
    align-items: center;
}


  
.exp-left{
    flex: 1;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
}

.exp-right{
    flex: 1;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
    padding-right: 2em;
}

.exp-heading{ 
    /* margin-bottom: 50px; */
    text-align: center;
    font-size : 20px;

}


.timeline 
{ 
    list-style-type: none;
    position: relative;
}
.timeline:before 
{
    content : ''; 
    /* background : #555;  */
    display : inline-block;
    position : absolute ;
    left: 29px; 
    width: 2px;
    height : 100%;
    z-index: 1;
}

.timeline li 
{
   margin-top: 20px;
   margin-bottom: 20px;
   margin-left: 20px;
   /* background : #fff; */
}

.timeline li h4 
{ 
    /* background : #000; */
    padding : 5px 0 5px 10px; 
    /* color : #fff; */
    font-size : 17px;
    font-weight:  800;

}
.timeline li h4 span { 
    font-weight:  500;
    font-size : 15px;

    /* color : #ccc ; */
}

.timeline li:before
{
   content :''; 
   background : #fff ; 
   display : inline-block; 
   position: absolute; 
   border-radius: 50%;
   border:3px solid #000; 
   left: 20px; 
   width : 20px; 
   height : 20px;
   z-index: 1;
}

@media screen and (max-width:700px) {
    .experience{
        height: auto;

      flex-direction: column;
      text-align: center;
      margin-top: 10em;
    }

    .exp-left {
      width: 100%;
      height: 100%;
    }


    .exp-right{
      padding: 20px;
      height: 100%;

    }

    .timeline 
    { 
        padding  :0 2em 2em 2em ;
    }
}