.menu {
    width: 300px;
    height: 100vh;
    position: fixed;
    top: 0;
    right: -300px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 1s ease;
}
.menu.active {
    right: 0;
}
.menu ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 30px;
    font-weight: 300;
    width: 60%;
}
.menu ul li {
    margin-bottom: 25px;
}
.menu ul li a {
    font-size: inherit;
    color: inherit;
    text-decoration: none;
}
.menu ul li:hover {
    font-weight: 500;
}
