.topbar {
    width: 100%;
    height: 55px;
    position: fixed;
    top: 0;
    z-index: 3;
    transition: all 1s ease;
}
.topbar .wrapper {
    padding: 5px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.topbar .wrapper .left {
    display: flex;
    align-items: center;
}
.topbar .wrapper .left .logo {
    font-size: 34px;
    font-weight: 700;
    text-decoration: none;
    color: inherit;
    margin-right: 40px;
}
.topbar .wrapper .left .itemContainer {
    display: flex;
    align-items: center;
    margin-left: 30px;
}
.topbar .wrapper .left .itemContainer .icon {
    font-size: 18px;
    margin-right: 5px;
}
.topbar .wrapper .left .itemContainer span {
    font-size: 15px;
    font-weight: 500;
}
.topbar .wrapper .right .hamburger {
    width: 26px;
    height: 21px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
}
.topbar .wrapper .right .hamburger span {
    width: 100%;
    height: 3px;
    background-color: #616161;
    transform-origin: left;
    transition: all 2s ease;
}
.topbar.active {
    background-color: #616161;
    color: white;
}
.topbar.active .hamburger span:first-child {
    background-color: white;
    transform: rotate(45deg);
}
.topbar.active .hamburger span:nth-child(2) {
    opacity: 0;
}
.topbar.active .hamburger span:last-child {
    background-color: white;
    transform: rotate(-45deg);
}

@media screen and (max-width: 480px) {
    .topbar .wrapper .left .logo {
        font-size: 30px;
    }
}