.skills { 
    height: 100vh;
    /* display: flex; */
    align-items: center;
    padding-bottom: 1em;
    padding-top: 2.2em;
}

.skills-headings{ 
    width: 100%;
    justify-content: space-between;
    text-align: center;
    padding-top: 1em;
    padding-bottom: 2em;
}

.container { 
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.container-div{ 
    padding-bottom: 60px;
    width: 25%;
    justify-content: center;
}

.icon { 
    text-align: center;
}

.icon-img { 
    font-size: 5em;
    padding-bottom: 1rem;
}

@media screen and (max-width: 480px) {
    .container-div {
        width: 30%;
        justify-content: space-between;
    }

    h3 { 
        font-size: 14px;
        padding-top: 1rem;
        text-align: center;
    }
}